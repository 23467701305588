"use client";

import { useLazyQuery, useMutation } from "@apollo/client";
import { useMsal } from "@azure/msal-react";
import {
  NO_ORGANIZATION_REDIRECT,
  RECOVERY_PASSWORD_WITH_TOKEN,
  REFRESH_TOKEN_PERSIST,
  USER_LANG,
  USER_TOKEN_PERSIST,
} from "@constants/global";
import Microsoft from "@public/svg/microsoft";
import PasswordInput from "components/PasswordInput";
import { useUserStoreContext } from "context/userStore/userStore.provider";
import { useVendor } from "context/vendor/vendor.provider";
import { gqlCoins, gqlUser } from "gql";
import {
  IMicrosoftLoginResponse,
  ISocialNetworkLogInInput,
  ISocialNetworkLogInRes,
} from "gql/User/mutations";
import setLanguage from "next-translate/setLanguage";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { isEmail } from "react-multi-email";
import { PUBLIC_ROUTES } from "routes/routes";
import { Button, Input } from "rsuite";
import { forceRefetchQueries } from "settings/apollo";
import { IUser } from "types/User.types";
import { getQueryOperator } from "utils/helpers";
import LinkedInButton from "./LinkedIn";

interface ILoginForm {
  callback?: (error?: string) => void;
  redirect?: string;
  invitationCode?: string;
}

const LoginForm = (props: ILoginForm) => {
  const router = useRouter();
  const { t } = useTranslation("common");
  const { callback, invitationCode, redirect } = props;
  const { instance } = useMsal();

  const { vendor } = useVendor();

  const { setRefreshToken, setAccessToken, setUser, setBasketProducts } =
    useUserStoreContext((store) => ({
      setRefreshToken: store.setRefreshToken,
      setAccessToken: store.setAccessToken,
      setUser: store.setUser,
      setBasketProducts: store.setBasketProducts,
    }));

  const [getUserBasket] = useLazyQuery(gqlCoins.queries.USER_BASKET, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted: ({ basket }) => {
      const { basketMemory } = basket;
      setBasketProducts(
        basketMemory.map(({ product, quantity }) => ({
          product,
          quantity,
        })) || []
      );
    },
  });

  const [loginWithMicrosoft, { loading }] = useMutation<
    { loginWithMicrosoft: IMicrosoftLoginResponse },
    { microsoftLogInInput: ISocialNetworkLogInInput }
  >(gqlUser.mutations.LOGIN_WITH_MICROSOFT, {
    refetchQueries: [getQueryOperator(gqlUser.queries.GET_FULL_USER)],
    awaitRefetchQueries: true,
    onCompleted({ loginWithMicrosoft }) {
      onCompletedLogin(loginWithMicrosoft);

      if (callback) {
        callback();
        return;
      }

      // * if the user came from an invitation link, redirect to inv
      if (invitationCode) {
        router.push({
          pathname: PUBLIC_ROUTES.invitation.path,
          query: {
            t: invitationCode,
            redirect: redirect as string,
          },
        });
        return;
      }
    },
  });

  const handleMicrosoftLogin = async () => {
    instance.loginRedirect({
      prompt: "select_account",
      scopes: ["User.Read"],
    });
  };

  useEffect(() => {
    instance.handleRedirectPromise().then((tokenResponse) => {
      if (tokenResponse) {
        loginWithMicrosoft({
          variables: {
            microsoftLogInInput: {
              accessToken: tokenResponse?.accessToken,
            },
          },
        });
      }
    });
  }, []);

  const [getUser, { data: getUserData }] = useLazyQuery<
    { user: IUser },
    { accessToken: string }
  >(gqlUser.queries.GET_FULL_USER, {
    fetchPolicy: "network-only",

    onCompleted: ({ user }) => {
      if (user && user.lang) {
        setLanguage(user.lang);
        localStorage.setItem(USER_LANG, user.lang);
      } else {
        setLanguage("en");
        localStorage.setItem(USER_LANG, "en");
      }

      setUser(user);

      if (user?.selectedVendor?.enableCoins) {
        getUserBasket();
      }
    },
  });

  const [login, { loading: loginLoading, error: loginError }] = useMutation(
    gqlUser.queries.LOGIN,
    {
      onCompleted({ login }) {
        onCompletedLogin(login);
      },
      onError({ message }) {
        if (callback) callback(message);
      },
    }
  );

  const onCompletedLogin = (login: ISocialNetworkLogInRes) => {
    const { accessToken, refreshToken } = login;

    setAccessToken(accessToken);
    setRefreshToken(refreshToken);

    localStorage.setItem(USER_TOKEN_PERSIST, accessToken);
    localStorage.setItem(REFRESH_TOKEN_PERSIST, refreshToken);

    getUser({
      variables: {
        accessToken,
      },
    });
  };

  const {
    handleSubmit: handleLoginSubmit,
    control: loginControl,
    formState: { errors },
  } = useForm();

  const handleLogin = async ({ email, password }) => {
    try {
      const { data } = await login({
        variables: {
          loginInput: {
            email,
            password,
          },
        },
      });

      if (data && data.login) {
        onCompletedLogin(data.login);
      }
    } catch (e) {
      console.error("Login error:", e);
    }
  };

  const onRecoverPassoword = () => {
    router.push(RECOVERY_PASSWORD_WITH_TOKEN);
  };

  const processUserData = async (userData) => {
    const {
      user: { vendorList },
    } = userData;

    if (callback) {
      callback();
      return;
    }

    // * if the user came from an invitation link, redirect to inv
    if (invitationCode) {
      router.push({
        pathname: PUBLIC_ROUTES.invitation.path,
        query: {
          t: invitationCode,
        },
      });
      return;
    }

    await forceRefetchQueries([gqlUser.queries.GET_FULL_USER]);

    if (!vendorList?.length) {
      router.push(NO_ORGANIZATION_REDIRECT);
    }
  };

  useEffect(() => {
    if (getUserData) {
      processUserData(getUserData);
    }
  }, [getUserData]);

  return (
    <form onSubmit={handleLoginSubmit(handleLogin)}>
      {!vendor?.onlyAdLogin && (
        <>
          <label className="font-input text-left font-semibold">
            {t("Ingresa tu correo electrónico")}
          </label>
          <Controller
            name="email"
            defaultValue=""
            control={loginControl}
            rules={{
              required: true,
              validate: (value) => isEmail(value),
            }}
            render={({ field }) => (
              <Input
                {...field}
                className="mt-2"
                placeholder={t("Ingresa tu correo electrónico")}
              />
            )}
          />
          {errors && errors.email && (
            <small className="w-full text-red-500">
              {t("Debes ingresar un e-mail válido")}
            </small>
          )}
          <label className="font-input mt-6 block text-left font-semibold">
            {t("Ingresa tu contraseña")}
          </label>
          <Controller
            name="password"
            defaultValue=""
            control={loginControl}
            rules={{ required: true }}
            render={({ field: { ...rest }, fieldState }) => (
              <PasswordInput
                {...rest}
                {...fieldState}
                placeholder={t("Ingresa tu contraseña2")}
              />
            )}
          />
          {errors && errors.password && (
            <small className="w-full text-red-500">
              {t("Debes ingresar una contraseña")}
            </small>
          )}
          <div className="mt-4 flex items-center">
            <div
              className="font-input ml-auto"
              style={{ color: "#5A27E7", cursor: "pointer" }}
              onClick={onRecoverPassoword}
              aria-hidden
            >
              {t("No recuerdo mi contraseña")}
            </div>
          </div>
        </>
      )}

      <div className="mt-6 flex w-full flex-col items-center gap-2 md:mt-10">
        {!vendor?.onlyAdLogin && (
          <>
            <div className="flex w-full justify-center">
              <Button
                className="w-screen p-4"
                appearance="primary"
                loading={loginLoading}
                type="submit"
              >
                {t("Ingresar a tu cuenta")}
              </Button>
            </div>

            {vendor?.enableLoginWithLinkedin && (
              <LinkedInButton
                onCompleted={onCompletedLogin}
                parentLoading={loginLoading}
              />
            )}
          </>
        )}

        {vendor?.enableMicrosoftLogin && (
          <Button
            className="w-full rounded-lg border border-gray-300 bg-indigo-400 p-4 text-white dark:border-gray-700 dark:bg-indigo-800"
            appearance="primary"
            onClick={handleMicrosoftLogin}
            loading={loading}
          >
            <div className="flex w-full items-center justify-center gap-2">
              {!loading && <Microsoft />}
              <span>{t("Inicio de sesión con Microsoft")}</span>
            </div>
          </Button>
        )}
      </div>
      {loginError && (
        <p className="mt-4 w-full text-center text-red-500">
          {loginError.message}
        </p>
      )}
    </form>
  );
};

export default LoginForm;

interface Props {
  width?: number;
  height?: number;
}

function Microsoft(props: Props) {
  const { width = 16, height = 16 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none">
      <g clipPath="url(#clip0_256_13910)">
        <path d="M11.906 11.406H.5V0h11.406v11.406z" fill="#F1511B" />
        <path d="M24.5 11.406H13.094V0H24.5v11.406z" fill="#80CC28" />
        <path d="M11.906 24H.5V12.594h11.406V24z" fill="#00ADEF" />
        <path d="M24.5 24H13.094V12.594H24.5V24z" fill="#FBBC09" />
      </g>
      <defs>
        <clipPath id="clip0_256_13910">
          <path fill="#fff" transform="translate(.5)" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Microsoft;

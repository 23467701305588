import HeaderBgBlue from "components/Layout/Sidebar/HeaderBgBlue";
import VideoLogIn from "../Login/components/VideoLogIn";
import React from "react";

interface LayoutVideoAuroraProps {
  readonly children: React.ReactNode;
  readonly renderVideo?: boolean;
}

export default function LayoutVideoAurora(props: LayoutVideoAuroraProps) {
  const { renderVideo = true } = props;
  return (
    <div className="relative grid h-screen min-h-dvh w-full items-start overflow-hidden bg-white dark:bg-gray-900">
      <HeaderBgBlue />
      <div className="mx-auto grid h-full w-full max-w-max-size-grid items-center overflow-y-auto px-4 align-middle">
        <div
          className={`grid h-auto w-full grid-cols-1 gap-10 align-middle ${renderVideo ? "md:grid-cols-2" : ""} `}
        >
          {/* Left column (VideoLogIn) */}
          {renderVideo && (
            <div className="relative hidden h-full w-full items-center overflow-hidden md:grid">
              <div className="sticky top-6 flex h-min">
                <VideoLogIn />
              </div>
            </div>
          )}

          {/* Right column with content */}

          <div className="z-5 grid h-auto w-full">{props.children}</div>
        </div>
      </div>

      {/* footer at the bottom with Groowcity C 2025 */}
      <div className="absolute bottom-0 w-full pb-4 text-center text-xs text-gray-400 dark:text-gray-500">
        Groowcity © 2025
      </div>
    </div>
  );
}

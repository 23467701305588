import Icon from "components/Icon";
import React, { useState } from "react";
import { Input, InputGroup, InputProps } from "rsuite";

const PasswordInput = React.forwardRef((props: InputProps, ref) => {
  const [passwordType, setPasswordType] = useState<boolean>(true);

  const handleReveal = () => {
    setPasswordType(!passwordType);
  };
  return (
    <InputGroup
      inside
      className={` ${props.className ? props.className : "mt-2 p-0"}`}
    >
      <Input
        ref={ref}
        {...props}
        type={`${passwordType ? "password" : "text"}`}
      />
      <InputGroup.Addon onClick={handleReveal} className="h-full">
        {!passwordType && (
          <Icon
            icon={["fal", "eye"]}
            className="animate__animated animate__fadeIn pr-2 pt-4 text-gray-700 dark:text-gray-200"
            style={{ fontSize: "1.2rem" }}
          />
        )}
        {passwordType && (
          <Icon
            icon={["fal", "eye-slash"]}
            className="animate__animated animate__fadeIn pr-2 pt-4 text-gray-700 dark:text-gray-200"
            style={{ fontSize: "1.2rem" }}
          />
        )}
      </InputGroup.Addon>
    </InputGroup>
  );
});

PasswordInput.displayName = "PasswordInput";

export default PasswordInput;

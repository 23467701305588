import { LOGO_DARK } from "@constants/global";
import SVG from "components/SVG";

interface ILeftImagePublic {
  backgroundsvg: string;
  className?: string;
}

const LeftImagePublic = (props: ILeftImagePublic) => {
  const { backgroundsvg } = props;

  return (
    <div
      {...props}
      className="animate__animated animate__fadeIn flex h-full justify-center p-12"
      style={{
        background: `url(${backgroundsvg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="z-50 flex">
        <div className="self-end">
          <SVG src={LOGO_DARK} width={100} height={30} />
        </div>
      </div>
    </div>
  );
};

export default LeftImagePublic;

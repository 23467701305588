import LogoGroow from "components/Layout/Sidebar/components/header/ui/LogoGroow";

export default function VideoLogIn() {
  return (
    <div className="mx-4 h-auto w-full">
      <div className="relative aspect-[4/5] w-full overflow-hidden rounded-2xl">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="grid h-full w-full rounded-2xl object-cover"
        >
          <source src="/video/backgroundGroow.mp4" type="video/mp4" />
          Tu navegador no soporta el tag de video.
        </video>
        {/* Overlay */}
        <div className="absolute inset-0 rounded-2xl bg-gradient-to-b from-black/50 to-black/20"></div>

        {/* Logo */}
        <div className="absolute top-8 grid w-full justify-center">
          <LogoGroow redirect={false} dark={true} marginLeft="0px" />
        </div>
      </div>
    </div>
  );
}
